.card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.card img {
    padding: 10px;
    width: 95%;
    height: 75%;
}

.product_titile {
    font-size: 1.2rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

@media only screen and (max-width:580px) {
    .card {
        display: block;
        margin: auto;
        width: 80%;
    }

    .card img {
        padding: 10px;
        width: 50%;
        height: 65%;
    }
}