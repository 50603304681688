.navbar {
    background-color: #ffffff;
}

.nav-link {
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
}

.navbar-collapse>ul>li {
    padding: 0 20px;
}

.nav-link:hover {
    color: #66ac4c
}